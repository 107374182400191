import React, { useEffect, useCallback, type FC, useContext, useRef, useState } from 'react';
import { defineMessages } from 'react-intl-next';

import { Inline, xcss } from '@atlaskit/primitives';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';
import { useCanUpgradeAndPayForProductUser } from '@confluence/experiment-more-product-users-can-upgrade-pay';
import {
	ExperienceFailure,
	ExperienceSuccess,
	ExperienceTrackerContext,
	AUA_FREE_PILL_EXPERIENCE,
} from '@confluence/experience-tracker';

import { useAutoUpgradeAwareness } from '../../hooks/useAutoUpgradeAwareness';
import { useGetUserCount } from '../../hooks/useGetUserCount';
import { useButtonEventHandler } from '../../hooks/analytics-handlers/useButtonEventHandler';
import { AUTO_UPGRADE_AWARENESS_FREE_PILL_TOUCHPOINT } from '../../hooks/analytics-handlers/analyticsConstants';
import { LazyAutoUpgradeAwarenessModal } from '../modal';

import { HighUserCountButton, LowUserCountButton } from './UserCountButton';

const i18n = defineMessages({
	upgrade: {
		id: 'experiment-auto-upgrade-awareness.button.upgrade',
		defaultMessage: 'Upgrade',
		description: 'Upgrade message',
	},
});

type AutoUpgradeAwarenessFreePillComponentProps = {
	isEligibleForMpu?: boolean;
	mpuAnalyticsAttributes?: {
		isBillingAdmin?: boolean;
		isSiteAdmin?: boolean;
		mpuCohort?: string;
	};
	userCount: number;
	auaAnalyticAttributes?: { autoUpgradeAwarenessExperimentCohort?: string };
};

const AutoUpgradeAwarenessFreePillComponent: FC<AutoUpgradeAwarenessFreePillComponentProps> = ({
	isEligibleForMpu,
	mpuAnalyticsAttributes,
	userCount,
	auaAnalyticAttributes,
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const { eventHandler } = useButtonEventHandler();

	const isUserCountLow = userCount <= 2;

	useEffect(() => {
		if (userCount) {
			eventHandler({
				action: 'viewed',
				mpuAnalyticsAttributes,
				isEligibleForMpu,
				auaAnalyticAttributes,
				userCount,
			});
		}
	}, [mpuAnalyticsAttributes, isEligibleForMpu, auaAnalyticAttributes, userCount, eventHandler]);

	const handleOnClick = useCallback(() => {
		eventHandler({
			action: 'clicked',
			mpuAnalyticsAttributes,
			isEligibleForMpu,
			auaAnalyticAttributes,
			userCount,
		});
		setIsModalOpen(true);
	}, [
		mpuAnalyticsAttributes,
		isEligibleForMpu,
		auaAnalyticAttributes,
		userCount,
		eventHandler,
		setIsModalOpen,
	]);

	return (
		<>
			{isUserCountLow ? (
				<LowUserCountButton text={i18n.upgrade} onClick={() => handleOnClick()} />
			) : (
				<HighUserCountButton text={i18n.upgrade} onClick={() => handleOnClick()} />
			)}
			{isModalOpen && (
				<LazyAutoUpgradeAwarenessModal
					touchpointId={AUTO_UPGRADE_AWARENESS_FREE_PILL_TOUCHPOINT}
					closeModal={() => setIsModalOpen(false)}
				/>
			)}
		</>
	);
};

const autoUpgradeAwarenessFreePillWrapper = xcss({
	paddingRight: 'space.200',
});
export const AutoUpgradeAwarenessFreePill: FC = withTransparentErrorBoundary({
	attribution: Attribution.GROWTH_LUNA,
})(() => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);
	const hasExperienceTrackerStartedRef = useRef<Boolean>(false);

	if (!hasExperienceTrackerStartedRef.current) {
		experienceTracker.start({
			name: AUA_FREE_PILL_EXPERIENCE,
		});
		hasExperienceTrackerStartedRef.current = true;
	}

	const { userCount, error: userCountError, loading: userCountLoading } = useGetUserCount();

	const {
		analyticsAttributes: mpuAnalyticsAttributes,
		isEligibleForMpu,
		loading: mpuLoading,
		error: mpuError,
	} = useCanUpgradeAndPayForProductUser();

	const { auaAnalyticAttributes } = useAutoUpgradeAwareness();

	useEffect(() => {
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'exposed',
				actionSubject: 'autoUpgradeAwarenessFreePill',
				attributes: {
					growthFeature: 'autoUpgradeAwarenessFreePill',
				},
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const loading = mpuLoading || userCountLoading;
	const error = mpuError || userCountError;

	if (loading) {
		return null;
	}

	if (error) {
		const errorMessages = {
			userCountError: userCountError?.message,
			mpuError: mpuError?.message,
		};

		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'failed',
				actionSubject: 'autoUpgradeAwarenessFreePill',
				source: 'autoUpgradeAwarenessFreePill',
				attributes: {
					growthFeature: 'autoUpgradeAwarenessFreePill',
					error: errorMessages,
				},
			},
		}).fire();

		return (
			<ExperienceFailure name={AUA_FREE_PILL_EXPERIENCE} error={error} attributes={errorMessages} />
		);
	}

	return (
		<Inline xcss={autoUpgradeAwarenessFreePillWrapper}>
			<AutoUpgradeAwarenessFreePillComponent
				mpuAnalyticsAttributes={mpuAnalyticsAttributes}
				isEligibleForMpu={isEligibleForMpu}
				userCount={userCount}
				auaAnalyticAttributes={auaAnalyticAttributes}
			/>
			<ExperienceSuccess name={AUA_FREE_PILL_EXPERIENCE} />
		</Inline>
	);
});

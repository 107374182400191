export const LEARN_MORE_LINK =
	'https://confluence.atlassian.com/display/ConfCloud/Confluence+Cloud+plans';

export enum SECTION_MESSAGE_APPEARANCE_TYPES {
	DEFAULT = 'default',
	FLAT = 'flat',
	INLINE = 'inline',
	INLINE_MODAL = 'inline-modal',
}

export const STANDARD_UPSELL_ENABLED_FF = 'confluence.frontend.show.standard.upsell';

export const UP_FLOW_EP_MESSAGE_ID = 'confluence-up-flow-modal';

export const PERSISTENT_UPGRADE_EP_MESSAGE_ID = 'upflow-confluence-persistent-upgrade';

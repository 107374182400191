import React, { useCallback, useContext } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import type { UpFlowContextType } from '@confluence/change-edition';
import {
	ConfluenceEdition,
	PERSISTENT_UPGRADE_EP_MESSAGE_ID,
	UpFlowContext,
} from '@confluence/change-edition';
import { LoadableLazy } from '@confluence/loadable';
import { useCanUpgradeAndPayForProductUser } from '@confluence/experiment-more-product-users-can-upgrade-pay';
import { useAutoUpgradeAwareness } from '@confluence/experiment-auto-upgrade-awareness';

import {
	PERSISTENT_UPGRADE_FLOW_UPGRADE,
	PERSISTENT_UPGRADE_FLOWID,
	PERSISTENT_UPGRADE_TOUCHPOINT_BANNER,
	PERSISTENT_UPGRADE_TOUCHPOINT_BUTTON,
} from './constants';
import { constructUIEvent } from './constructPersistentUpgradeAnalyticsEvents';
import { useExperimentTrialPlanComparison } from './TrialPlanComparisonExperiment/useExperimentTrialPlanComparison';

const LazyExperimentPlanSelectionModal = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ExperimentPlanSelectionModal" */ './TrialPlanComparisonExperiment/ExperimentPlanSelectionModal'
			)
		).ExperimentPlanSelectionModal,
});

export const useUpflowModal = (touchpointId?) => {
	const { isTrialPlanComparisonExperiment, openPlanSelectionDialog } =
		useExperimentTrialPlanComparison();
	const { analyticsAttributes: mpuAnalyticsAttributes, isEligibleForMpu } =
		useCanUpgradeAndPayForProductUser();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { showUpFlow, currentEdition } = useContext<UpFlowContextType>(UpFlowContext);
	const { auaAnalyticAttributes } = useAutoUpgradeAwareness();

	const openUpflowModal = useCallback(() => {
		const fireUIEvent = (eventAction: string) => {
			createAnalyticsEvent(
				constructUIEvent({
					eventAction,
					touchpointSubject:
						touchpointId === PERSISTENT_UPGRADE_TOUCHPOINT_BUTTON
							? PERSISTENT_UPGRADE_TOUCHPOINT_BUTTON
							: PERSISTENT_UPGRADE_TOUCHPOINT_BANNER,
					currentEdition,
					touchpointId,
					flowId: PERSISTENT_UPGRADE_FLOWID,
					experimentAttributes: {
						...(isEligibleForMpu && mpuAnalyticsAttributes),
						...auaAnalyticAttributes,
					},
				}),
			).fire();
		};

		fireUIEvent('clicked');

		if (openPlanSelectionDialog) {
			openPlanSelectionDialog({ touchpointId });
			return;
		}

		// Launch Upflow SPA
		showUpFlow({
			touchpointId,
			flow: PERSISTENT_UPGRADE_FLOW_UPGRADE,
			targetEdition: ConfluenceEdition.STANDARD,
			epMessageId: PERSISTENT_UPGRADE_EP_MESSAGE_ID,
		});
	}, [
		openPlanSelectionDialog,
		showUpFlow,
		touchpointId,
		createAnalyticsEvent,
		isEligibleForMpu,
		mpuAnalyticsAttributes,
		auaAnalyticAttributes,
		currentEdition,
	]);

	const UpflowModalMountPoint = () => {
		return (
			<>
				{isTrialPlanComparisonExperiment && currentEdition === ConfluenceEdition.FREE && (
					<LazyExperimentPlanSelectionModal />
				)}
			</>
		);
	};

	return {
		openUpflowModal,
		isTrialPlanComparisonExperiment,
		UpflowModalMountPoint,
	};
};

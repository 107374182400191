export enum MPU_COHORTS {
	NOT_ENROLLED = 'not-enrolled',
	VARIATION = 'variation',
	CONTROL = 'control',
}

export enum MPU_STATSIG {
	EXPERIMENT_NAME = 'more_user_products_can_upgrade_and_pay_in_product',
	EXPERIMENT_KEY = 'cohort',
}

export enum MPU_QUERY {
	HAMS_PRODUCT_KEY = 'confluence.ondemand',
	OFFERING_KEY = '2eda1119-fab4-4699-ab37-ff8f12052cde',
}

import { useContext } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';

import { SPAViewContext } from '@confluence/spa-view-context';
import {
	useCanUpgradeAndPayForProductUser,
	useCanAddBillingForTrialProductUser,
} from '@confluence/experiment-more-product-users-can-upgrade-pay';

import {
	AUTO_UPGRADE_AWARENESS_EXPERIMENT,
	type AUAExperimentCohort,
} from '../experiment-auto-upgrade-awareness-types';

export const useAutoUpgradeAwareness = () => {
	const { isSiteAdmin } = useContext(SPAViewContext);

	const { isEligibleForMpuVariant: isEligibleForMpuFreeVariant } =
		useCanUpgradeAndPayForProductUser(isSiteAdmin);
	const { isEligibleForVariation: isEligibleForMpuTrialVariant } =
		useCanAddBillingForTrialProductUser(isSiteAdmin);

	const isEligible = isSiteAdmin || isEligibleForMpuFreeVariant || isEligibleForMpuTrialVariant;

	if (!isEligible) {
		return {
			isAUAVariantEnabled: false,
			auaAnalyticAttributes: {},
		};
	}

	const experimentConfig = FeatureGates.getExperiment(AUTO_UPGRADE_AWARENESS_EXPERIMENT);

	const cohort = experimentConfig.get<AUAExperimentCohort>('cohort', 'not-enrolled');
	const isAAEnabled = experimentConfig.get<boolean>('isAAEnabled', false);
	const isABEnabled = experimentConfig.get<boolean>('isABEnabled', false);

	const isAUAVariantEnabled = cohort === 'test' && isABEnabled && isEligible;

	const auaAnalyticAttributes =
		(isAAEnabled || isABEnabled) && cohort !== 'not-enrolled'
			? { autoUpgradeAwarenessExperimentCohort: cohort, isAAEnabled, isABEnabled }
			: {};

	return {
		auaAnalyticAttributes,
		isAUAVariantEnabled,
	};
};

import type { ConfluenceEdition } from '@confluence/change-edition';
import { type AUAExperimentCohort } from '@confluence/experiment-auto-upgrade-awareness';

import { PERSISTENT_UPGRADE_ANALYTICS_SOURCE } from './constants';

// Utility functions to build all GasV3 events to be published by banner and avatar menu.

// Analytics Event Types:
export const UI_EVENT_TYPE = 'sendUIEvent';
export const OPERATIONAL_EVENT_TYPE = 'sendOperationalEvent';

export type AnalyticEventProps = {
	type: string;
	data: {
		action: string;
		actionSubject: string;
		source: string;
		attributes: any;
		actionSubjectId?: string | null;
		name?: string | null;
	};
};

export type UIEventProps = {
	eventAction: string;
	touchpointSubject: string;
	currentEdition?: ConfluenceEdition | null;
	touchpointId?: string | null;
	flowId?: string | null;
	experimentAttributes?: {
		isSiteAdmin?: boolean;
		isBillingAdmin?: boolean;
		mpuCohort?: string;
		autoUpgradeAwarenessExperimentCohort?: AUAExperimentCohort;
		isABEnabled?: boolean;
		isAAEnabled?: boolean;
	};
};

// Construct a ui event
export const constructUIEvent = ({
	eventAction,
	touchpointSubject,
	currentEdition,
	touchpointId,
	flowId,
	experimentAttributes,
}: UIEventProps): AnalyticEventProps => {
	return {
		type: UI_EVENT_TYPE,
		data: {
			action: eventAction,
			actionSubject: touchpointSubject,
			source: PERSISTENT_UPGRADE_ANALYTICS_SOURCE,
			attributes: {
				currentEdition,
				touchpointId,
				flowId,
				growthFeature: PERSISTENT_UPGRADE_ANALYTICS_SOURCE,
				...experimentAttributes,
			},
		},
	};
};

export interface ResponseError extends Error {
	status?: number;
}

export type FailedEvent = {
	error: ResponseError;
};

import { useContext } from 'react';

import { ConfluenceEdition } from '@confluence/change-edition';
import { SPAViewContext } from '@confluence/spa-view-context';
import { useSessionData } from '@confluence/session-data';
import { useCanUpgradeAndPayForProductUser } from '@confluence/experiment-more-product-users-can-upgrade-pay';

export const usePersistentUpgradeButton = () => {
	const { isSiteAdmin } = useContext(SPAViewContext);

	const { isEligibleForMpuVariant } = useCanUpgradeAndPayForProductUser();

	const { edition } = useSessionData();

	const isEligible = (edition === ConfluenceEdition.FREE && isSiteAdmin) || isEligibleForMpuVariant;

	return {
		isPersistentUpgradeButtonEligible: isEligible,
	};
};

import { useCallback, useEffect } from 'react';
import type { Action } from 'react-sweet-state';
import { createHook, createStore, createActionsHook } from 'react-sweet-state';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { useMultivariantFeatureFlag } from '@confluence/session-data';
import { ConfluenceEdition } from '@confluence/change-edition';

interface DialogState {
	isOpen: boolean;
	touchpointId?: string;
}

const initialState: DialogState = { isOpen: false };
const actions = {
	showModal:
		({ touchpointId }): Action<DialogState> =>
		({ setState }) => {
			setState({ isOpen: true, touchpointId });
		},
	closeModal:
		(): Action<DialogState> =>
		({ setState }) => {
			setState({ isOpen: false });
		},
};

const store = createStore({
	initialState,
	actions,
	name: 'ExperimentalTrialPlanDialogStore',
});
const useModalActions = createActionsHook(store);

export const useModal = createHook(store);

interface UseExperimentTrialPlanComparisonParams {
	edition?: ConfluenceEdition;
	isSiteAdmin?: boolean;
}

const FF_TRIAL_PLAN_COMPARISON_EXPERIMENT = 'confluence.frontend.trial.plan.comparison.experiment';

export const useExperimentTrialPlanComparison = ({
	edition,
	isSiteAdmin,
}: UseExperimentTrialPlanComparisonParams = {}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { cohort, fireExposureEvent } = useMultivariantFeatureFlag(
		'confluence.frontend.trial.plan.comparison.experiment',
		['control', 'not-enrolled', 'experiment'],
		'not-enrolled',
	);
	const isEligibleForExperiment = edition === ConfluenceEdition.FREE && isSiteAdmin;

	useEffect(() => {
		if (isEligibleForExperiment) {
			fireExposureEvent();
			// Thi event is needed to start funnel analysis on Amplitude.
			// Please remove after the experiment runtime completes.
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					source: 'trialPlanComparisonExperiment',
					action: 'exposed',
					actionSubject: 'feature',
					attributes: {
						flagKey: FF_TRIAL_PLAN_COMPARISON_EXPERIMENT,
						value: cohort,
					},
					tags: ['measurement'],
				},
			}).fire();
		}
	}, [fireExposureEvent, isEligibleForExperiment, createAnalyticsEvent, cohort]);

	const { showModal } = useModalActions();

	const isTrialPlanComparisonExperiment = cohort === 'experiment';

	const openPlanSelectionDialog = useCallback(
		({ touchpointId }: any) => {
			void showModal({ touchpointId });
		},
		[showModal],
	);

	return {
		openPlanSelectionDialog: isTrialPlanComparisonExperiment ? openPlanSelectionDialog : undefined,
		isTrialPlanComparisonExperiment,
	};
};
